<template>
  <div>
    <b-card title="Catégorie FAQ">
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
        >
          <b-form-group label="Dénomination">
            <b-form-input
              v-model="$v.section.$model"
              :formatter="formatter"
              :class="{ 'is-invalid': $v.section.$error }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-col sm="12">
        <div
          v-if="process"
          class="d-flex justify-content-center mt-1"
        >
          <b-spinner
            variant="primary"
            label="Loading..."
          />
        </div>
        <div class="mt-3">
          <b-button
            variant="secondary"
            class="float-left"
            :disabled="process"
            @click="resetForm"
          >
            {{ $t("form.refresh") }}
          </b-button>
          <b-button
            type="submit"
            variant="outline-primary"
            class="ml-2 float-right"
            :disabled="process"
            @click="tryToSave"
          >
            Ajouter
          </b-button>
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      section: null,
    }
  },
  validations: {
    section: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      success: 'faqSectionSuccess',
      error: 'faqSectionError',
      process: 'faqSectionProcess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Section FAQ',
            text: 'Ajouté avec succes',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Section FAQ',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['newFaqSection']),
    formatter(value) {
      return value.toUpperCase()
    },
    resetForm() {
      this.section = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.newFaqSection({
        section: this.section,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
 .cursor {
   cursor: pointer
 }
</style>
